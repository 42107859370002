import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogPostCard = ({ post }) => {
  const image = getImage(post.frontmatter.header_image);
  return (
    <div className="col-md-4">
      <div className="card mb-4">
        <GatsbyImage
          image={image}
          alt={post.frontmatter.title}
          className="card-img-top blog-card-img"
        />
        <div className="card-body">
          <h5 className="card-title">{post.frontmatter.title}</h5>
          <p>
            <small className="text-muted">
              Published {post.frontmatter.datetime}
            </small>
          </p>
          <p className="card-text">{post.excerpt}</p>
          <a className="text-button me-3" href={post.fields.slug}>
            Read More →
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
