import * as React from "react";
import Navbar from "./Navbar";
import "../styles/style.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const HeroHeader = ({ page }) => {
  const image = getImage(page.frontmatter.header_image);

  return (
    <div className="hero-container">
      <GatsbyImage
        image={image}
        alt=""
        style={{
          gridArea: "1/1",
        }}
        layout="fullWidth"
        aspectratio={3 / 1}
      />
      <div className="hero-overlay">
        <div className="w-100 align-self-start">
          <Navbar />
        </div>
        <div className="hero-text-new container">
          <h1 className="display-3 text-start">{page.frontmatter.title}</h1>
          <p className="display-6 text-start">{page.frontmatter.description}</p>
        </div>
      </div>
    </div>
  );
};

export default HeroHeader;
