import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const FeaturedBlogPostCard = ({ post }) => {
  const image = getImage(post.frontmatter.header_image);
  return (
    <div className="card my-3">
      <div className="row g-0">
        <div className="col-md-5">
          <GatsbyImage
            image={image}
            alt={post.frontmatter.title}
            className="horizontal-card-image rounded-start"
          />
        </div>
        <div className="col-md-5">
          <div className="card-body">
            <h3 className="card-title display-6">{post.frontmatter.title}</h3>
            <p className="card-text">
              <small className="text-muted">
                Published {post.frontmatter.datetime}
              </small>
            </p>
            <p className="card-text">{post.excerpt}</p>
            <a className="text-button me-3" href={post.fields.slug}>
              Read More →
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBlogPostCard;
