import * as React from "react";
import "../styles/style.scss";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import FeaturedBlogPostCard from "../components/FeaturedBlogPostCard";
import BlogPostCard from "../components/BlogPostCard";

// markup
const Blog = ({ data }) => {
  const pageContent = data.blogIndexContent.nodes[0];
  const blogposts = data.blogPostsRemark.nodes;

  return (
    <Layout page={pageContent}>
      <Seo
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
      />
      <div className="container mt-5">
        {/* The most recent post is displayed on a larger card. */}
        <FeaturedBlogPostCard post={blogposts[0]} />
        <div className="row mt-5">
          {/* Map over the blog posts, minus the most recent one which is displayed as a featured post */}
          {blogposts.slice(1).map((blogpost) => (
            <BlogPostCard post={blogpost} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query blogIndexQuery {
    blogIndexContent: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { type: { eq: "page_content" } }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
    blogPostsRemark: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blogpost" } } }
      sort: { fields: frontmatter___datetime, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          datetime(formatString: "dddd, D MMMM YYYY")
          header_image {
            childImageSharp {
              gatsbyImageData(width: 600, placeholder: BLURRED)
            }
          }
        }
        excerpt
        fields {
          slug
        }
      }
    }
  }
`;

export default Blog;
