import React from "react";
import HeroHeader from "./HeroHeader";
import Footer from "./Footer";
import "../styles/style.scss";

const Layout = ({ page, children }) => (
  <>
    <div id="wrapper" className="wrapper">
      <HeroHeader page={page} />
      {children}
      <Footer />
    </div>
  </>
);

export default Layout;
